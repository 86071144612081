<template>
  <OrkhonBase>
    <main class="pf-c-page__main">
      <section class="pf-c-page__main-section pf-m-light">
        <a @click="$router.go(-1)">&lt; Back</a>
      </section>
      <section class="pf-c-page__main-section pf-m-light">
        <h1 class="pf-c-title pf-m-4xl">Settings</h1>
      </section>
      <section class="pf-c-page__main-section pf-m-light" v-if="!keyExists">
        <p>
          You do not have a cryptographic key installed. You can generate one
          below. Or you can import one if you have geneated it previously.
          <br /><br /><br />
        </p>

        <div class="pf-c-alert pf-m-danger">
          <div class="pf-c-alert__icon">
            <i class="fas fa-fw fa-exclamation-circle"></i>
          </div>
          <p class="pf-c-alert__title">
            <strong
              >You have to save this key to be able to access your encrypted
              notes from other browsers, or from this browser if you clean your
              local storage.</strong
            >
          </p>
        </div>

        <br />
        <button
          type="button"
          @click="generate"
          class="pf-c-button pf-m-primary"
        >
          Generate</button
        >&nbsp;
        <label for="upload_file" class="pf-c-button pf-m-primary"
          ><i class="fas-cloud-upload"></i> Import</label
        >
        <input
          type="file"
          @change="importKey"
          formnovalidate
          id="upload_file"
          name="file"
          accept="*.jwk"
          style="display: none"
        />
      </section>
      <section class="pf-c-page__main-section pf-m-light" v-if="keyExists">
        <p>You have your key installed. You can export it for safe keeping.</p>
        <button
          type="button"
          @click="exportKey"
          class="pf-c-button pf-m-primary"
        >
          Export</button
        >&nbsp;
        <button
          type="button"
          @click="deleteKey"
          class="pf-c-button pf-m-danger"
        >
          Delete
        </button>
      </section>
    </main>
  </OrkhonBase>
</template>

<script>
import OrkhonBase from "@/components/OrkhonBase";
import Crypto from "@/utils/crypto";

export default {
  name: "Settings",
  components: {
    OrkhonBase,
  },
  data() {
    return {
      keyExists: false,
    };
  },
  methods: {
    generate() {
      Crypto.generateKey().then((keyPair) => {
        console.log(keyPair);
        Crypto.saveKey(keyPair).then(this.updateKeyStatus);
        Crypto.downloadKey(keyPair.privateKey);
      });
    },
    exportKey() {
      Crypto.getKey().then((keyPair) => Crypto.downloadKey(keyPair.privateKey));
    },
    importKey(e) {
      let vm = this;
      const reader = new FileReader();
      reader.onload = function () {
        Crypto.importKey(
          JSON.parse(new TextDecoder().decode(reader.result))
        ).then((keyPair) => {
          Crypto.saveKey(keyPair);
          vm.updateKeyStatus();
        });
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    },
    deleteKey() {
      Crypto.deleteKey().then(this.updateKeyStatus);
    },
    updateKeyStatus() {
      this.$nextTick(async function () {
        let key = await Crypto.getKey();
        this.keyExists = key != null;
      });
    },
  },
  mounted: function () {
    this.updateKeyStatus();
  },
};
</script>
