<template>
  <li
    class="pf-c-nav__item pf-m-expandable"
    :class="{ 'pf-m-expanded': expanded }"
  >
    <button
      class="pf-c-nav__link"
      id="expandable-example1"
      @click.prevent="toggleExpand"
    >
      {{ title }}
      <span class="pf-c-nav__toggle">
        <span class="pf-c-nav__toggle-icon">
          <i class="fas fa-angle-right"></i>
        </span>
      </span>
    </button>
    <section class="pf-c-nav__subnav" :hidden="!expanded">
      <ul class="pf-c-nav__list">
        <slot />
      </ul>
    </section>
  </li>
</template>

<script>
export default {
  name: "pf-nav-section",
  props: {
    title: String,
  },
  data: function () {
    return {
      expanded: true,
    };
  },
  methods: {
    toggleExpand: function () {
      this.expanded = !this.expanded;
    },
  },
};
</script>
