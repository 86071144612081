<template>
  <div id="app" class="pf-c-page">
    <header class="pf-c-page__header">
      <div class="pf-c-page__header-brand">
        <a class="pf-c-page__header-brand-link">
          <img class="pf-c-brand" src="/images/logof.png" />
        </a>
      </div>
    </header>
    <main class="pf-c-page__main">
      <section class="pf-c-page__main-section pf-m-light">
        <div class="pf-l-bullseye ork-banner-container">
          <div class="pf-l-bullseye__item ork-banner">
            <div class="pf-u-text-align-center">
              <div class="ork-head pf-u-font-weight-bold">Secure Notes</div>
              <div class="ork-head-sub pf-u-color-200">
                Orkhon is another note taking application with strong emphasis
                on security. Your data is encrypted in your browser and can not
                be decrypted on our servers.
              </div>
              <br />
              <button type="button" class="pf-c-button pf-m-primary" @click="register">
                Signup</button
              >&nbsp;
              <button class="pf-c-button pf-m-secondary" type="button" @click="login">
                Login
              </button>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { keycloak } from "@/main.js";
export default {
  name: "Landing",
  methods: {
    login() {
      keycloak.login();
    },
    register() {
      keycloak.register();
    },
  },
};
</script>
