<template>
  <div class="editor">
    <EditorMenuBar :editor="editor" v-slot="{ commands, isActive, focused }">
      <div
        class="pf-c-toolbar menubar is-hidden"
        :class="{ 'is-focused': focused }"
      >
        <div class="pf-c-toolbar__content">
          <div class="pf-c-toolbar__content-section">
            <div class="pf-c-toolbar__group pf-m-icon-button-group">
              <div
                class="pf-c-toolbar__item"
                :class="{ 'is-active': isActive.bold() }"
              >
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.bold"
                >
                  <i class="fas fa-bold"></i>
                </button>
              </div>
              <div
                class="pf-c-toolbar__item"
                :class="{ 'is-active': isActive.italic() }"
              >
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.italic"
                >
                  <i class="fas fa-italic"></i>
                </button>
              </div>
              <div
                class="pf-c-toolbar__item"
                :class="{ 'is-active': isActive.strike() }"
              >
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.strike"
                >
                  <i class="fas fa-strikethrough"></i>
                </button>
              </div>
              <div
                class="pf-c-toolbar__item"
                :class="{ 'is-active': isActive.underline() }"
              >
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.underline"
                >
                  <i class="fas fa-underline"></i>
                </button>
              </div>
            </div>
            <div class="pf-c-toolbar__group pf-m-icon-button-group">
              <div
                class="pf-c-toolbar__item"
                :class="{ 'is-active': isActive.code() }"
              >
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.code"
                >
                  <i class="fas fa-code"></i>
                </button>
              </div>
              <div
                class="pf-c-toolbar__item"
                :class="{ 'is-active': isActive.paragraph() }"
              >
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.paragraph"
                >
                  <i class="fas fa-paragraph"></i>
                </button>
              </div>
              <div
                class="pf-c-toolbar__item"
                :class="{ 'is-active': isActive.heading({ level: 1 }) }"
              >
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.heading({ level: 1 })"
                >
                  <i class="fas fa-heading"></i>1
                </button>
              </div>
              <div
                class="pf-c-toolbar__item"
                :class="{ 'is-active': isActive.heading({ level: 2 }) }"
              >
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.heading({ level: 2 })"
                >
                  <i class="fas fa-heading"></i>2
                </button>
              </div>
              <div
                class="pf-c-toolbar__item"
                :class="{ 'is-active': isActive.heading({ level: 3 }) }"
              >
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.heading({ level: 3 })"
                >
                  <i class="fas fa-heading"></i>3
                </button>
              </div>
              <div
                class="pf-c-toolbar__item"
                :class="{ 'is-active': isActive.bullet_list() }"
              >
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.bullet_list"
                >
                  <i class="fas fa-list"></i>
                </button>
              </div>
              <div
                class="pf-c-toolbar__item"
                :class="{ 'is-active': isActive.ordered_list() }"
              >
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.ordered_list"
                >
                  <i class="fas fa-list-ol"></i>
                </button>
              </div>
              <div
                class="pf-c-toolbar__item"
                :class="{ 'is-active': isActive.blockquote() }"
              >
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.blockquote"
                >
                  <i class="fas fa-quote-left"></i>
                </button>
              </div>
              <div
                class="pf-c-toolbar__item"
                :class="{ 'is-active': isActive.code_block() }"
              >
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.code_block"
                >
                  <i class="fas fa-file-code"></i>
                </button>
              </div>
              <div class="pf-c-toolbar__item">
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.horizontal_rule"
                >
                  <i class="fas fa-ruler"></i>
                </button>
              </div>
              <div class="pf-c-toolbar__item">
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.undo"
                >
                  <i class="fas fa-undo"></i>
                </button>
              </div>
              <div class="pf-c-toolbar__item">
                <button
                  class="pf-c-button pf-m-plain"
                  type="button"
                  @click="commands.redo"
                >
                  <i class="fas fa-redo"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EditorMenuBar>

    <EditorContent class="editor__content" :editor="editor" spellcheck="false" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from "tiptap-extensions";

import debounce from '../utils/debunce'

export default {
  name: "EditorBasic",
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: ["value"],
  data: function () {
    return {
      editor: null,
      emitAfterOnUpdate: false,
    };
  },
  mounted: function () {
    var vm = this;
    this.editor = new Editor({
      onUpdate: debounce(function ({ getHTML }) {
        vm.emitAfterOnUpdate = true;
        vm.$emit("input", getHTML());
      }, 1000),
      content: this.value,
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
      ],
    });
    this.editor.focus('end');
  },
  beforeDestroy: function () {
    if (this.editor) this.editor.destroy();
  },
  watch: {
    value(val) {
      if (this.emitAfterOnUpdate) {
        this.emitAfterOnUpdate = false;
        return;
      }
      if (this.editor) this.editor.setContent(val);
    },
  },
};
</script>

<style scoped>
/* TODO remove unused styles */
.pf-c-toolbar__item.is-active {
  background-color: rgba(0, 0, 0, 0.1);
}

.editor {
  position: relative;
}

.editor__content {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  margin: 0em 2em 
}

.editor__content * {
  caret-color: currentColor;
}

.editor__content pre {
  padding: 0.7rem 1rem;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 0.8rem;
  overflow-x: auto;
}

.editor__content pre code {
  display: block;
}

.editor__content p code {
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: 700;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
}
.editor__content ol,
.editor__content ul {
  padding-left: 1rem;
}
.editor__content li > ol,
.editor__content li > p,
.editor__content li > ul {
  margin: 0;
}
.editor__content a {
  color: inherit;
}
.editor__content blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
  padding-left: 0.8rem;
  font-style: italic;
}
.editor__content blockquote p {
  margin: 0;
}
.editor__content img {
  max-width: 100%;
  border-radius: 3px;
}
.editor__content table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
.editor__content table td,
.editor__content table th {
  min-width: 1em;
  border: 2px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
.editor__content table td > *,
.editor__content table th > * {
  margin-bottom: 0;
}
.editor__content table th {
  font-weight: 700;
  text-align: left;
}
.editor__content table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}
.editor__content table .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: #adf;
  pointer-events: none;
}
.editor__content .tableWrapper {
  margin: 1em 0;
  overflow-x: auto;
}
.editor__content .resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
.menubar {
  padding-top: 0;
  -webkit-transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
}
.menubar.is-hidden {
  visibility: hidden;
  opacity: 0;
}
.menubar.is-focused {
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility 0.2s, opacity 0.2s;
  transition: visibility 0.2s, opacity 0.2s;
}
.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}
.menubar__button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.menubar span.menubar__button {
  font-size: 13.3333px;
}
</style>
