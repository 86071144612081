<template>
  <div class="editor">
    <EditorMenuBubble :editor="editor" :keep-in-bounds="keepInBounds" v-slot="{ commands, isActive, menu }">
        <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <i class="fas fa-bold"></i>
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <i class="fas fa-italic"></i>
        </button>


      </div>
    </EditorMenuBubble>
    <EditorContent class="editor__content" :editor="editor" spellcheck="false" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBubble } from "tiptap";
import { Bold, Italic, Link } from "tiptap-extensions";
import debounce from "../utils/debunce";

export default {
  name: "EditorTodo",
  components: {
    EditorContent,
    EditorMenuBubble,
  },
  props: ["value"],
  data: function () {
    return {
      editor: null,
      emitAfterOnUpdate: false,
      keepInBounds: true,
    };
  },
  mounted: function () {
    var vm = this;
    this.editor = new Editor({
      onUpdate: debounce(function ({ getHTML }) {
        vm.emitAfterOnUpdate = true;
        vm.$emit("input", getHTML());
      }, 1000),
      onFocus: function() {
        vm.$emit('focus');
      },
      onBlur: function() {
        vm.$emit('blur');
      },
      content: this.value,
      extensions: [new Link(), new Bold(), new Italic()],
    });
  },
  beforeDestroy: function () {
    if (this.editor) this.editor.destroy();
  },
  watch: {
    value(val) {
      if (this.emitAfterOnUpdate) {
        this.emitAfterOnUpdate = false;
        return;
      }
      if (this.editor) this.editor.setContent(val);
    },
  },
};
</script>

<style scoped>
.menububble {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 20;
  background: #000;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;
}
.menububble.is-active {
  opacity: 1;
  visibility: visible;
}
.menububble__button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #fff;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}
.menububble__button:last-child {
  margin-right: 0;
}
.menububble__button:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}
.menububble__button.is-active {
  background-color: hsla(0, 0%, 100%, 0.2);
}
.menububble__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.menububble__input {
  font: inherit;
  border: none;
  background: transparent;
  color: #fff;
}
</style>
