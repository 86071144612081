<template>
  <li class="pf-c-menu__list-item">
    <button class="pf-c-menu__item" type="button" @click="$emit('click')">
      <span class="pf-c-menu__item-main">
        <span class="pf-c-menu__item-text">{{ title }}</span>
      </span>
    </button>
    <slot />
  </li>
</template>

<script>
export default {
  name: "pf-menu-item",
  props: {
      title: String
  }
};
</script>
