import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Settings from '@/views/Settings.vue'
import NewBook from "@/views/NewBook.vue";
import Landing from '@/views/Landing'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      default: Landing,
      authenticated: Home
    }
  },
  {
    path: '/books/:id',
    name: 'Book',
    components: {
      default: Landing,
      authenticated: Home
    }
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    components: {
      default: Landing,
      authenticated: Settings
    }
  },
  {
    path: '/newbook',
    name: 'NewBook',
    components: {
      default: Landing,
      authenticated: NewBook
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
