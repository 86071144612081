<template>
  <span>

    <template v-if="isAuthenticated">
      <router-view name="authenticated"/>
    </template>
  
    <template v-else>
      <router-view/>
    </template>

  </span>
</template>

<script>
import { keycloak } from "@/main.js";
export default {
  name: 'App',
  data() {
    return {
      isAuthenticated: keycloak.authenticated
    }
  }
}
</script>

<style>
@import "~@patternfly/patternfly/patternfly.css";
@import "~@patternfly/patternfly/utilities/Text/text.css";
@import "~@patternfly/patternfly/utilities/Alignment/alignment.css";
@import "~@patternfly/patternfly/utilities/Display/display.css";
@import "app.css";
</style>
