<template>
  <span>
    <OrkhonBase @burger-toggle="navExpanded = !navExpanded">
      <div class="pf-c-page__sidebar" :class="{ 'pf-m-expanded': navExpanded }">
        <div class="pf-c-page__sidebar-body">
          <div class="pf-c-toolbar__content">
            <div class="pf-c-toolbar__content-section">
              <div class="pf-c-toolbar__group pf-m-icon-button-group">
                <div class="pf-c-toolbar__item">
                  <button
                    class="pf-c-button pf-m-plain"
                    type="button"
                    @click="$router.push('/NewBook')"
                  >
                    <i class="fas fa-plus-circle fa-lg ork-button"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <pf-nav>
            <pf-nav-section
              v-for="(value, folder) in booksByFolder"
              :key="folder"
              :title="folder"
            >
              <pf-nav-item
                v-for="book in value"
                :key="book.id"
                :selected="book.id == currentBook.id"
                @click="navigateToBook(book.id)"
                >{{ book.metadata.map["elementName"] }}
              </pf-nav-item>
            </pf-nav-section>
          </pf-nav>
        </div>
      </div>
      <main class="pf-c-page__main" tabindex="-1">
        <section v-if="bookLoaded" class="pf-c-page__main-section pf-m-light">
          <div class="pf-l-split">
            <div class="pf-l-split__item pf-m-fill">
              <pf-inline-edit :value="currentBook.name" @input="renameBook" />
            </div>
            <div class="pf-l-split__item">
              <i class="fas fa-lock" v-if="currentBook.encrypted" title="Encrypted"></i>
              <BookContextMenu
                @delete="deleteBook(currentBook)"
                @move="moveBook(currentBook, $event)"
                :folders="folders"
                :folder="currentBook.folder"
              />
            </div>
          </div>
        </section>
        <section
          v-if="currentBook.type == 'TODO'"
          class="pf-c-page__main-section pf-m-no-padding pf-m-padding-on-md"
        >
          <Todo :book="currentBook" @change="autoSave"></Todo>
        </section>
        <section
          v-if="currentBook.type == 'LONG_FORM'"
          class="pf-c-page__main-section pf-m-no-padding pf-m-padding-on-md"
        >
          <LongForm :book="currentBook" @change="autoSave"></LongForm>
        </section>
        <section
          v-if="currentBook.type == 'MASONRY'"
          class="pf-c-page__main-section pf-m-no-padding pf-m-padding-on-md"
        >
          <Masonry :book="currentBook" @change="autoSave"></Masonry>
        </section>
        <section
          v-if="currentBook.type == '__ENCRYPTION_ERROR__'"
          class="pf-c-page__main-section pf-m-no-padding pf-m-padding-on-md"
        >
          <div class="pf-c-alert pf-m-danger">
            <div class="pf-c-alert__icon">
              <i class="fas fa-fw fa-exclamation-circle"></i>
            </div>
            <p class="pf-c-alert__title">
              <strong>
                Error loading encrypted note. Either you did not import your
                key, or this note is encrypted with a different key.</strong
              >
              <br />
              Go to <a @click="$router.push('/settings')">settings</a> and
              import the correct key.
            </p>
          </div>
        </section>
      </main>
    </OrkhonBase>
  </span>
</template>

<script>
import OrkhonBase from "@/components/OrkhonBase";
import Todo from "@/components/Todo";
import LongForm from "@/components/LongForm";
import BookContextMenu from "@/components/BookContextMenu";
import Masonry from "@/components/Masonry";
import Crypto from "@/utils/crypto";

import { keycloak, axios } from "@/main.js";

export default {
  name: "Home",
  components: {
    OrkhonBase,
    Todo,
    LongForm,
    BookContextMenu,
    Masonry,
  },
  data: function () {
    return {
      books: [],
      booksByFolder: {},
      currentBook: {},
      currentBookId: null,
      folders: {},
      newBookModalVisible: false,
      navExpanded: false,
      token: keycloak.tokenParsed,
    };
  },
  created() {
    // We did this to make hot reload work better. ie: load the page contents back
    if (!this.currentBook.id) {
      this.list(() => this.loadBook(this.$route.params.id));
    }
  },
  computed: {
    bookLoaded: function () {
      return Object.keys(this.currentBook).length != 0;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.list(() => {
        vm.loadBook(to.params.id);
      });
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.loadBook(to.params.id);
    next();
  },
  methods: {
    list: function (callback) {
      let vm = this;
      axios.get("/api/element").then(function (response) {
        let books = response.data;
        vm.folders = Array.from(
          new Set(books.map((b) => b.metadata.map["elementFolder"]))
        );
        let s = {};
        vm.folders.forEach((f) => {
          let k = f;
          if (typeof f == "undefined") k = "nofolder";
          s[k] = books.filter((b) => b.metadata.map["elementFolder"] == f);
        });
        vm.booksByFolder = s;
        vm.books = books;
        if (callback) callback();
      });
    },
    loadBook: function (bookId) {
      let book = this.books.find((b) => b.id == bookId);
      let vm = this;
      axios
        .get(
          "/api/element/" + book.id + "/content",
          book.metadata.map["encrypted"] == "true"
            ? {
                responseType: "arraybuffer",
              }
            : {}
        )
        .then(async function (response) {
          let currentBook = {
            id: book.id,
            name: book.metadata.map["elementName"],
            type: book.metadata.map["elementType"],
            folder: book.metadata.map["elementFolder"],
            encrypted: book.metadata.map["encrypted"] == "true",
          };

          if (response.data) {
            if (book.metadata.map["encrypted"] == "true") {
              try {
                currentBook.content = response.data.byteLength > 0 ? await Crypto.decrypt(response.data) : {};
              } catch (e) {
                currentBook.type = "__ENCRYPTION_ERROR__";
              }
            } else {
              currentBook.content = response.data;
            }
          }
          vm.currentBook = currentBook;
          vm.navExpanded = false;
        });
    },
    moveBook: function (book, destination) {
      let vm = this;
      let data = {
        metadata: {
          elementFolder: destination,
        },
      };
      axios.put("/api/element/" + book.id, data).then(function () {
        vm.list();
      });
    },
    navigateToBook: function (bookId) {
      this.currentBookId = bookId;
      this.$router.push({ name: "Book", params: { id: bookId } }).catch(() => {});
    },
    autoSave: async function (book) {
      this.currentBook = book;
      let data = new FormData();
      let content = book.encrypted
        ? await Crypto.encrypt(book.content)
        : JSON.stringify(book.content);
      data.append("file", new Blob([content]));
      axios
        .post("/api/element/" + book.id + "/content", data)
        .then(function () {
          //vm.notes = response.data;
        });
    },
    deleteBook: function (book) {
      let vm = this;
      axios.delete("/api/element/" + book.id).then(function () {
        vm.list();
        vm.navigateToBook(vm.books[0].id);
      });
    },
    renameBook: function (newName) {
      this.currentBook.name = newName;
      let data = {
        metadata: {
          elementName: newName,
        },
      };
      let vm = this;
      axios.put("/api/element/" + this.currentBook.id, data).then(function () {
        vm.list();
      });
    },
  },
  watch: {
    newBookModalVisible() {
      if (this.newBookModalVisible)
        document.body.classList.add("pf-c-backdrop__open");
      else document.body.classList.remove("pf-c-backdrop__open");
    },
  },
};
</script>
