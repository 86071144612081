
import { get, set, del } from 'idb-keyval';
import { saveAs } from 'file-saver';

export default {
    getKey() {
        return get("my-orkhon-key");
    },
    generateKey() {
        return window.crypto.subtle
            .generateKey(
                {
                    name: "RSA-OAEP",
                    modulusLength: 4096,
                    publicExponent: new Uint8Array([1, 0, 1]),
                    hash: "SHA-256",
                },
                true,
                ["encrypt", "decrypt"]
            )
    },
    downloadKey(key) {
        window.crypto.subtle.exportKey("jwk", key)
            .then(jwkKey => {
                const keyString = JSON.stringify(jwkKey);
                const keyBlob = new Blob([keyString], { type: "text/plain;charset=utf-8" });
                saveAs(keyBlob, "my-orkhon-key.jwk");
            });
    },
    saveKey(key) {
        return set("my-orkhon-key", key);
    },
    async importKey(privateKeyObject) {
        let privateKey = await window.crypto.subtle.importKey("jwk", privateKeyObject,
            {
                name: "RSA-OAEP",
                hash: "SHA-256",
            },
            true,
            ["decrypt"]
        );
        const publicKeyFilter = ({ alg, e, ext, kty, n }) => ({ alg, e, ext, kty, n });
        var publicKeyObject = publicKeyFilter(privateKeyObject);
        publicKeyObject.keyOps = ["encrypt"];

        let publicKey = await window.crypto.subtle.importKey("jwk", publicKeyObject,
            {
                name: "RSA-OAEP",
                hash: "SHA-256",
            },
            true,
            ["encrypt"]
        );

        return {
            publicKey: publicKey,
            privateKey: privateKey
        }
    },
    async encrypt(message) {
        const key = await this.getKey();
        return await window.crypto.subtle.encrypt(
            {
                name: "RSA-OAEP"
            },
            key.publicKey,
            new TextEncoder().encode(JSON.stringify(message))
        );
    },
    async decrypt(message) {
        const key = await this.getKey();
        const decoded = await window.crypto.subtle.decrypt(
            {
                name: "RSA-OAEP"
            },
            key.privateKey,
            message
        );
        return JSON.parse(new TextDecoder().decode(decoded));
    },
    deleteKey() {
        return del("my-orkhon-key");
    }
}
