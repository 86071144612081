<template>
  <div
    class="pf-c-dropdown"
    :class="{ 'pf-m-expanded': isExpanded }"
    v-click-outside="hide"
  >
    <button @click="toggle" class="pf-c-dropdown__toggle pf-m-plain">
      <span v-if="title != null" class="pf-c-dropdown__toggle-text">
        {{ title }}
      </span>
      <i :class="iconStyle" />
    </button>
    <ul
      class="pf-c-dropdown__menu pf-m-align-right"
      :class="'pf-m-align-' + align"
      :hidden="!isExpanded"
    >
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  name: "pf-dropdown",
  props: {
    title: String,
    expanded: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: "fa-caret-down",
    },
    align: {
      type: String,
      default: "right",
      validator: function (value) {
        return ["left", "right"].indexOf(value) !== -1;
      },
    },
  },
  data: function () {
    return {
      isExpanded: this.expanded,
    };
  },
  watch: {
    expanded: function () {
      this.isExpanded = this.expanded;
    },
  },
  computed: {
    iconStyle: function () {
      return [
        "fas",
        this.iconClass,
        {
          "pf-c-dropdown__toggle-icon": this.title != null,
        },
      ];
    },
  },
  methods: {
    toggle: function () {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) this.$emit("onOpen");
    },
    hide: function () {
      this.isExpanded = false;
      this.$emit("onClose");
    },
  },
};
</script>
