<template>
  <div>
    <button
      class="pf-c-menu-toggle pf-m-plain"
      :class="{ 'pf-m-expanded': isExpanded }"
      type="button"
      @click="toggle"
    >
      <i class="fas fa-ellipsis-v"></i>
    </button>
    <div :hidden="!isExpanded">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "pf-menu-toggle",
  data: function () {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggle: function () {
      this.isExpanded = !this.isExpanded;
    },
    hide: function () {
      this.isExpanded = false;
    },
  },
};
</script>
