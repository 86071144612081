<template>
  <li class="pf-c-nav__item">
    <a
      class="pf-c-nav__link"
      :class="{ 'pf-m-current': selected }"
      @click="$emit('click')"
    >
      <slot />
    </a>
  </li>
</template>

<script>
export default {
  name: "pf-nav-item",
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
