<template>
  <nav class="pf-c-nav">
    <ul class="pf-c-nav__list">
      <slot />
    </ul>
  </nav>
</template>

<script>
export default {
    name: "pf-nav"
}
</script>
