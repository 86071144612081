<template>
  <div class="pf-c-select" v-click-outside="hide">
    <button
      class="pf-c-select__toggle"
      :class="{ 'pf-m-typeahead': editable }"
      type="button"
      @click="toggle"
    >
      <div class="pf-c-select__toggle-wrapper">
        <span v-if="!editable" class="pf-c-select__toggle-text">
          {{ displayTitle }}
        </span>
        <input
          v-if="editable"
          class="pf-c-form-control pf-c-select__toggle-typeahead"
          type="text"
          :value="displayTitle"
          @input="$emit('input', $event.target.value)"
        />
      </div>
      <span class="pf-c-select__toggle-arrow">
        <i class="fas fa-caret-down"></i>
      </span>
    </button>
    <ul class="pf-c-select__menu" role="listbox" :hidden="!isExpanded">
      <li role="presentation" v-for="item in items" :key="item">
        <button
          class="pf-c-select__menu-item"
          :class="{ 'pf-m-selected': item == selected }"
          role="option"
          @click="onSelect(item)"
        >
          {{ item }}
          <span v-if="item == selected" class="pf-c-select__menu-item-icon">
            <i class="fas fa-check"></i>
          </span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "pf-select",
  props: {
    title: {
      type: String,
      default: "Select",
    },
    items: Array,
    editable: {
      type: Boolean,
      default: false,
    },
    initial: String,
  },
  data: function () {
    return {
      isExpanded: false,
      selected: this.initial,
      displayTitle: this.title,
    };
  },
  methods: {
    onSelect: function (item) {
      this.displayTitle = item;
      this.selected = item;
      this.$emit("input", item);
      this.hide();
    },
    toggle: function () {
      this.isExpanded = !this.isExpanded;
    },
    hide: function () {
      this.isExpanded = false;
    },
  },
};
</script>
