<template>
  <div class="pf-c-content">
    <div class="pf-c-card">
      <ul class="pf-c-data-list pf-m-compact" role="list">
        <draggable
          v-model="book.content.todoItems"
          handle=".pf-c-data-list__item-draggable-button"
          @change="$emit('change', book)"
        >
          <TodoItem
            v-for="(todoItem, index) in book.content.todoItems"
            :item="todoItem"
            :key="index"
            @remove="removeItem(todoItem)"
            @change="$emit('change', book)"
          ></TodoItem>
        </draggable>
      </ul>
    </div>
    <button type="button" @click="addItem" class="pf-c-button pf-m-secondary">
      New Note
    </button>
  </div>
</template>

<script>
import TodoItem from "./TodoItem";
import draggable from "vuedraggable";

export default {
  name: "Todo",
  props: ["book"],
  components: {
    TodoItem,
    draggable,
  },
  watch: {
    book: {
      immediate: true,
      handler() {
        // TODO burdaki iflere gerek var mı sadeleşebilir mi? bir de todo content ile book content daha iyi isimledirmeli.
        if (!this.book.content.todoItems) {
          this.$set(this.book.content, "todoItems", []);
          this.book.content.todoItems = [];
          this.book.content.todoItems.push({ content: "first note" });
          this.$emit("change", this.book);
        }
      },
    },
  },
  methods: {
    addItem: function () {
      this.book.content.todoItems.push({ content: "new note" });
      this.$emit("change", this.book);
    },
    removeItem: function (item) {
      this.book.content.todoItems.splice(
        this.book.content.todoItems.indexOf(item),
        1
      );
      this.$emit("change", this.book);
    },
  },
};
</script>
