<template>
  <div class="pf-c-menu pf-m-flyout">
    <div class="pf-c-menu__content">
      <ul class="pf-c-menu__list">
        <slot />
      </ul>
    </div>
  </div>
</template>
<!-- https://github.com/vuejs/vue/issues/5538 --> 
<script>
export default {
  name: "pf-menu",
};
</script>
