<template>
  <OrkhonBase>
    <main class="pf-c-page__main">
      <section class="pf-c-page__main-section pf-m-light">
        <h1 class="pf-c-title pf-m-4xl">Create New Book</h1>
      </section>
      <section class="pf-c-page__main-section pf-m-light">
      </section>
      <section class="pf-c-page__main-section pf-m-light">
      <div class="pf-c-modal-box__body">
        <form
            novalidate
            class="pf-c-form pf-m-horizontal"
            @submit.prevent="addNewBook"
        >
          <div class="pf-c-form__group">
            <div class="pf-c-form__group-label">
              <label class="pf-c-form__label" for="book-name-form-name">
                <span class="pf-c-form__label-text">Name</span>
                <span class="pf-c-form__label-required">&#42;</span>
              </label>
            </div>
            <div class="pf-c-form__group-control">
              <input
                  v-model="book.name"
                  class="pf-c-form-control ork-input"
                  type="text"
                  id="book-name-form-name"
                  required
              />
            </div>
          </div>
          <div class="pf-c-form__group">
            <div class="pf-c-form__group-label">
              <label class="pf-c-form__label" for="book-type-form-name">
                <span class="pf-c-form__label-text">Type</span>
                <span class="pf-c-form__label-required">&#42;</span>
              </label>
            </div>
            <div class="pf-c-form__group-control" id="book-type-form-name">
              <div
                  class="pf-c-tile"
                  :class="{ 'pf-m-selected': book.type == 'TODO' }"
                  tabindex="0"
                  @click="book.type = 'TODO'"
              >
                <div class="pf-c-tile__header">
                  <div class="pf-c-tile__title">TODO</div>
                </div>
              </div>
              <div
                  class="pf-c-tile"
                  :class="{ 'pf-m-selected': book.type == 'LONG_FORM' }"
                  tabindex="1"
                  @click="book.type = 'LONG_FORM'"
              >
                <div class="pf-c-tile__header">
                  <div class="pf-c-tile__title">Long Form</div>
                </div>
              </div>
              <div
                  class="pf-c-tile"
                  :class="{ 'pf-m-selected': book.type == 'MASONRY' }"
                  tabindex="2"
                  @click="book.type = 'MASONRY'"
              >
                <div class="pf-c-tile__header">
                  <div class="pf-c-tile__title">Masonry</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pf-c-form__group">
            <div class="pf-c-form__group-label"></div>
            <div class="pf-c-form__group-control">
              <div class="pf-c-check">
                <input
                    class="pf-c-check__input"
                    type="checkbox"
                    v-model="book.encrypted"
                    id="encrypted-checkbox"
                    :disabled="!hasKey"
                />
                <label class="pf-c-check__label" :class="{'pf-m-disabled': !hasKey}" for="encrypted-checkbox"
                >Encrypted</label
                >
              </div>
              <small v-if="!hasKey">Go to <a @click="$router.push('/settings')">settings</a> and setup an encryption key to create encrypted notes</small>
            </div>
          </div>
        </form>
      </div>
      </section>
      <section class="pf-c-page__main-section pf-m-light">
        <button
            type="button"
            @click="addNewBook"
            class="pf-c-button pf-m-primary"
        >
          Create</button
        >&nbsp;
        <button
            type="button"
            @click="$router.go(-1)"
            class="pf-c-button pf-m-danger"
        >
          Back
        </button>
      </section>
    </main>
  </OrkhonBase>
</template>

<script>
import Crypto from "@/utils/crypto";
import {axios} from "@/main";
import OrkhonBase from "@/components/OrkhonBase";

export default {
  name: "NewBook",
  components: {
    OrkhonBase,
  },
  data() {
    return {
      book: {
        name: "",
        type: "TODO",
        encrypted: false,
      },
      hasKey: false,
    };
  },
  mounted() {
    Crypto.getKey().then((keyPair) => {
      console.log(keyPair);
      if (keyPair) this.hasKey = this.book.encrypted = true;
    });
  },
  methods: {
    addNewBook: function () {
      let vm = this;
      let data = {
        metadata: {
          elementName: vm.book.name,
          elementType: vm.book.type,
          encrypted: vm.book.encrypted + "",
        },
      };
      axios.put("/api/element", data).then(function (response) {
        vm.list(() => vm.navigateToBook(response.data.id));
      });
      vm.$router.go(-1);
    },
  },
};
</script>
