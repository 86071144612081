<template>
  <pf-dropdown
    iconClass="fa-ellipsis-v"
    @onClose="reset"
    :expanded="expanded"
    @onOpen="expanded = true"
  >
    <pf-dropdown-item
      @click="movePanelVisible = true"
      v-if="!movePanelVisible && !deletePanelVisible"
      >Move</pf-dropdown-item
    >
    <pf-dropdown-item
      @click="deletePanelVisible = true"
      v-if="!movePanelVisible && !deletePanelVisible"
      >Delete</pf-dropdown-item
    >
    <div :hidden="!movePanelVisible" style="min-width: 14rem">
      <div class="pf-l-flex pf-m-column">
        <div class="pf-l-flex">
          <div class="pf-l-flex__item">
            Select folder to move or create a new one:
          </div>
        </div>
        <div class="pf-l-flex">
          <div class="pf-l-flex__item">
            <pf-select
              :items="folders"
              v-model="toFolder"
              :editable="true"
              :initial="folder"
            />
          </div>
        </div>
        <div class="pf-l-flex">
          <div class="pf-l-flex__item">
            <button
              type="button"
              @click="
                $emit('move', toFolder);
                movePanelVisible = false;
                expanded = false;
              "
              class="pf-c-button pf-m-primary"
            >
              Move Book
            </button>
          </div>
          <div class="pf-l-flex__item">
            <button
              class="pf-c-button pf-m-secondary"
              type="button"
              @click="
                movePanelVisible = false;
                expanded = false;
              "
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div :hidden="!deletePanelVisible" style="min-width: 14rem">
      <div class="pf-l-flex pf-m-column">
        <div class="pf-l-flex">
          <div class="pf-l-flex__item">Are you sure!</div>
        </div>
        <div class="pf-l-flex">
          <div class="pf-l-flex__item">
            <button
              class="pf-c-button pf-m-danger"
              type="button"
              @click="
                $emit('delete');
                deletePanelVisible = false;
                expanded = false;
              "
            >
              Delete
            </button>
          </div>
          <div class="pf-l-flex__item">
            <button
              class="pf-c-button pf-m-secondary"
              type="button"
              @click="
                deletePanelVisible = false;
                expanded = false;
              "
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </pf-dropdown>
</template>
<script>
export default {
  name: "BookContextMenu",
  props: {
    folders: Array,
    folder: String,
  },
  data: function () {
    return {
      toFolder: "",
      movePanelVisible: false,
      deletePanelVisible: false,
      expanded: false,
    };
  },
  methods: {
    reset: function () {
      this.toFolder = "";
      this.movePanelVisible = false;
      this.deletePanelVisible = false;
    },
  },
};
</script>
