<template>
  <div>
    <div class="pf-l-bullseye">
      <div class="pf-l-bullseye__item">
        <div class="pf-c-card ork-masonry-form">
          <div class="pf-c-card__body">
            <form novalidate class="pf-c-form" @submit.prevent="addNote">
              <input
                type="text"
                v-model="newNoteTitle"
                class="pf-c-form-control"
                :placeholder="
                  titleFocused || contentFocused ? 'Title' : 'Take a note'
                "
                @focus="titleFocused = true"
                @blur="hideNewNote"
              />
              <editor-todo
                :hidden="!titleFocused && !contentFocused"
                v-model="newNoteContent"
                @focus="contentFocused = true"
                @blur="contentFocused = false"
              />
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="pf-c-content"
      v-masonry
      transition-duration="0.3s"
      item-selector=".ork-card"
    >
      <draggable v-model="book.content.items" @end="$redrawVueMasonry()">
        <div
          class="pf-c-card ork-card"
          v-for="(card, index) in book.content.items"
          :key="index"
        >
          <div class="pf-c-card__title">{{ card.title }}</div>
          <div class="pf-c-card__body">
            <editor-todo v-model="card.content" @input="$emit('change', book)" />
          </div>
          <div class="ork-masonry-toolbar pf-l-flex">
            <div class="pf-l-flex__item pf-m-align-right">
              <button class="pf-c-button pf-m-plain">
                <i class="fas fa-palette"></i>
              </button>
              <button class="pf-c-button pf-m-plain" @click="deleteNote(index)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import EditorTodo from "./EditorTodo";

export default {
  name: "Masonry",
  props: ["book"],
  components: {
    draggable,
    EditorTodo,
  },
  data: function () {
    return {
      titleFocused: false,
      contentFocused: false,
      newNoteTitle: "",
      newNoteContent: "Take a note",
    };
  },
  methods: {
    hideNewNote() {
      setTimeout(() => (this.titleFocused = false), 50);
    },
    addNote() {
      this.book.content.items.unshift({
        title: this.newNoteTitle,
        content: this.newNoteContent,
      });
      this.newNoteTitle = "";
      this.newNoteContent = "Take a note";
      this.$emit("change", this.book);
      this.$nextTick(() => this.$redrawVueMasonry());
    },
    deleteNote(index) {
      this.book.content.items.splice(index, 1);
      this.$emit("change", this.book);
      this.$nextTick(() => this.$redrawVueMasonry());
    }
  },
  watch: {
    book: {
      immediate: true,
      handler() {
        if (!this.book.content.items) {
          this.$set(this.book.content, "items", []);
        }
        this.$redrawVueMasonry();
      },
    },
  },
};
</script>

<style scoped>
.ork-card {
  width: 20rem;
  margin: 1em;
}
.ork-masonry-form {
  margin: 1rem;
  width: 32rem;
}

.ork-masonry-toolbar {
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: ease-in;
  transition-duration: 0.218s;
}

.ork-card:hover .ork-masonry-toolbar {
  opacity: 1;
}
</style>
