<template>
  <div class="pf-c-content">
    <EditorBasic
      v-model="book.content"
      @input="$emit('change', book)"
    />
  </div>
</template>

<script>
import EditorBasic from './EditorBasic'

export default {
  name: "LongForm",
  props: ["book"],
  components: {
      EditorBasic
  },
  watch: {
    book: {
      immediate: true,
      handler() {
        if (!this.book.content) {
          this.book.content = "<p>Your content here!</p>";
          this.$emit("change", this.book);
        }
      },
    },
  },
};
</script>
