<template>
  <div id="app" class="pf-c-page">
    <header class="pf-c-page__header">
      <div class="pf-c-page__header-brand">
        <div class="pf-c-page__header-brand-toggle">
          <button
            class="pf-c-button pf-m-plain"
            type="button"
            @click="$emit('burger-toggle')"
          >
            <i class="fas fa-bars"></i>
          </button>
        </div>
        <a class="pf-c-page__header-brand-link">
          <img class="pf-c-brand" src="/images/logof.png" />
        </a>
      </div>
      <div class="pf-c-page__header-tools">
        <div class="pf-c-page__header-tools-group">
          <div class="pf-c-page__header-tools-item">
            <pf-dropdown :title="token.name">
              <pf-dropdown-item @click="$router.push('/settings')"
                >Settings</pf-dropdown-item
              >
              <pf-dropdown-item @click="logout">Logout</pf-dropdown-item>
            </pf-dropdown>
          </div>
        </div>
      </div>
    </header>
    <slot />
  </div>
</template>

<script>
import { keycloak } from "@/main.js";

export default {
  name: "OrkhonBase",
  data() {
    return {
      token: keycloak.tokenParsed,
    };
  },
  methods: {
    logout() {
      keycloak.logout();
    },
  },
};
</script>
