import Vue from 'vue'
import App from '@/App.vue'
import Keycloak from 'keycloak-js'
import VueLogger from 'vuejs-logger'
import axios from 'axios'
import { VueMasonryPlugin } from 'vue-masonry';

Vue.config.productionTip = false

const isProduction = process.env.NODE_ENV === 'production';

const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};

Vue.use(VueLogger, options);
Vue.use(VueMasonryPlugin);

/* Patternfly Components Config */
import * as components from './components/pf'
import vClickOutside from 'v-click-outside'
import router from './router'

const ComponentLibrary = {
  install(Vue) {
    // components
    for (const componentName in components) {
      const component = components[componentName]
      Vue.component(component.name, component)
    }

    // directives
    Vue.use(vClickOutside)
  }
}
Vue.use(ComponentLibrary)

/* Axios Interceptor Config */
axios.interceptors.request.use(
  config => {
    if (keycloak.token) {
      config.headers['Authorization'] = 'Bearer ' + keycloak.token;
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });

/* Keycloak SSO Config */
let keycloak = isProduction ? new Keycloak("/keycloak-prod.json") : new Keycloak("/keycloak.json");

keycloak.init(
  {
    onLoad: 'check-sso',
    promiseType: 'native',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-sso.html'
  }
).then((auth) => {

  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')

  if (auth) {
    //Token Refresh
    setInterval(() => {
      keycloak.updateToken(70).then((refreshed) => {
        if (refreshed) {
          Vue.$log.info('Token refreshed: ' + refreshed);
        }
      }).catch(() => {
        Vue.$log.error('Failed to refresh token');
        keycloak.login();
      });
    }, 10000)
  } else {
    router.push('/').catch(() => { });
  }

}).catch((e) => {
  Vue.$log.error(e);
  Vue.$log.error("Authenticated Failed");
});

export { keycloak, axios }
