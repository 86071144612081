<template>
  <form
    @submit.prevent
    class="pf-c-inline-edit"
    :class="{ 'pf-m-inline-editable': isEditable }"
    v-click-outside="cancel"
  >
    <div class="pf-c-inline-edit__group">
      <div class="pf-c-inline-edit__value">{{ text }}</div>
      <div class="pf-c-inline-edit__action pf-m-enable-editable">
        <button
          class="pf-c-button pf-m-plain"
          type="button"
          @click="isEditable = true"
        >
          <i class="fas fa-pencil-alt"></i>
        </button>
      </div>
    </div>
    <div class="pf-c-inline-edit__group">
      <div class="pf-c-inline-edit__input">
        <input
          class="pf-c-form-control"
          type="text"
          v-model="text"
          @keyup.enter="ok"
          @keyup.escape="cancel"
        />
      </div>
      <div class="pf-c-inline-edit__group pf-m-action-group pf-m-icon-group">
        <div class="pf-c-inline-edit__action pf-m-valid">
          <button class="pf-c-button pf-m-plain" type="button" @click="ok">
            <i class="fas fa-check"></i>
          </button>
        </div>
        <div class="pf-c-inline-edit__action">
          <button class="pf-c-button pf-m-plain" type="button" @click="cancel">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "pf-inline-edit",
  props: {
    value: String,
  },
  data: function () {
    return {
      text: this.value,
      isEditable: false,
    };
  },
  watch: {
    value: function() {
      this.text = this.value
    }
  },
  methods: {
    ok: function () {
      this.$emit("input", this.text);
      this.isEditable = false;
    },
    cancel: function () {
      this.isEditable = false;
    },
  },
};
</script>
