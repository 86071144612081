<template>
  <li>
    <a
      class="pf-c-dropdown__menu-item"
      :class="disabled ? 'pf-m-disabled' : ''"
      :href="href"
      @click="$emit('click')"
    >
      <slot />
    </a>
  </li>
</template>

<script>
export default {
  name: "pf-dropdown-item",
  props: {
    href: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
