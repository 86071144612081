<template>
  <li
    class="pf-c-data-list__item"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="pf-c-data-list__item-row">
      <div class="pf-c-data-list__item-control">
        <button class="pf-c-data-list__item-draggable-button" type="button">
          <span class="pf-c-data-list__item-draggable-icon">
            <i class="fas fa-grip-vertical"></i>
          </span>
        </button>
      </div>
      <div class="pf-c-data-list__item-content" style="padding-bottom: 4px">
        <div class="pf-c-data-list__cell pf-m-align-left">
          <div>
            <editor-todo
              v-model="item.content"
              @input="$emit('change')"
            ></editor-todo>
          </div>
        </div>
      </div>
      <div class="pf-c-data-list__item-action">
        <div class="pf-c-data-list__action">
          <button
            @click="$emit('remove')"
            class="pf-c-button pf-m-secondary"
            :class="{ hidden: !hover }"
            type="button"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import EditorTodo from "./EditorTodo";

export default {
  name: "TodoItem",
  props: ["item"],
  components: {
    EditorTodo,
  },
  data: function () {
    return {
      hover: false,
    };
  },
  methods: {
    onSave: function (e) {
      this.item.content = e.target.innerText;
    },
  },
};
</script>

<style scoped>
.hidden {
  visibility: hidden;
}
</style>
